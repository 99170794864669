.pagination-container {
	display: flex;
	list-style-type: none;
   justify-content: center !important;
}
.pagination-container .pagination-item {
justify-content: center;
	height: 32px;
	text-align: center;
	margin: auto 4px;
	color:  #C4C4C4;

	display: flex;
	box-sizing: border-box;
	align-items: center;
	letter-spacing: 0.01071em;
	border-radius: 50px;
	font-size: 13px;
	min-width: 32px;
	border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 4px;
	
}
.pagination-container .pagination-item.dots:hover {
	background-color: transparent;
	cursor: default;
}
.pagination-container .pagination-item:hover {
	background-color: rgba(0, 0, 0, 0.04);
	cursor: pointer;
}
.pagination-container .pagination-item.selected {
   background: #0747A6;
border-radius: 4px;
height: 32px;
color: #fff;
}
.pagination-container .pagination-item .arrow::before {
	position: relative;
   /* top: 3pt;
	Uncomment this to lower the icons as requested in comments*/
	content: '';
   /* By using an em scale, the arrows will size with the font */
	display: inline-block;
	width: 0.4em;
	height: 0.4em;
	border-right: 0.12em solid #0747A6;
	border-top: 0.12em solid #0747A6;
	
}
.pagination-container .pagination-item .arrow.left {
	transform: rotate(-135deg) translate(-30%);
	
}
.pagination-container .pagination-item .arrow.right {
	transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
	pointer-events: none;
border-radius: 50%;
border: 1px solid #E5E5E5;
}
.pagination-item:last-child,.pagination-item:first-child{
   border-radius: 50%;
}
.pagination-container .pagination-item.disabled .arrow::before {
	border-right: 0.12em solid rgba(0, 0, 0, 0.43);
	border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
	background-color: transparent;
	cursor: default;
}
