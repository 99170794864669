@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
body {
  margin: 0;
  font-family: "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-image: url("./images/leftbg.svg"), url("./images/rightbg.svg");
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  min-height: 85vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-select {
  padding: 0.375rem 0.5rem 0.375rem 0.75rem !important;
}
.form-select:focus {
  box-shadow: none !important;
}

/* Toast */
.Toastify__toast--error {
  background-color: #d82a2a !important;
  color: #fff !important;
}

.Toastify__toast--success {
  background-color: #32c5a8 !important;
  color: #fff !important;
}

.Toastify__toast--error path,
.Toastify__toast--success path {
  fill: #fff !important;
}

tr {
  border: 1px solid #e5e5e5 !important;
}

/* Tooltip */
.data-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  /* line-height: 50px; */
  box-sizing: border-box;
  pointer-events: none;
}

.data-tooltip {
  z-index: 1;
  pointer-events: initial;
  /* cursor: pointer; */
}

.data-tooltip:hover:after {
  cursor: pointer;
}

.tooltip {
  width: 40%;
  visibility: hidden;
  background-color: #4779c3;
  color: #fff;
  padding: 4px 8px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.data-tooltip:hover + .tooltip {
  font-size: 14px;
  border-radius: 5px;
  visibility: visible;
  transition: opacity 0.5s ease;
  opacity: 1;
}
.success-green {
  color: #32c5a7 !important;
}
.warning-red {
  color: #ea5f5f !important;
}
.py-2-2 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.me-sm-4 {
  width: 280px !important;
}
.ms-sm-4 {
  width: 280px !important;
  margin-left: auto !important;
}

.secondary-blue {
  color: #4c9aff;
}
.btn-info {
  color: #fff !important;
  background-color: #0747a6 !important;
  border-color: #0747a6 !important;
  font-size: 0.75rem !important;
}
.table thead th {
  font-size: 12.5px !important;
}
.table td {
  font-size: 12.5px !important;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #a9a5a5;
}
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eeeeee;
}
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eeeeee;
  text-transform: capitalize !important;
}
.table tbody + tbody {
  border-top: 2px solid #eeeeee;
}

.table-head-bg {
  background: #f6f8ff !important;
  text-transform: capitalize !important;
}
.display {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08) !important;
}

.font-w600 {
  font-weight: 600;
}
.text-dark-black {
  color: #000 !important;
}
table.dataTable.no-footer {
  border-bottom: 0;
}
table.dataTable.display tbody td {
  border-color: #eeeeee;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #322740;
  background: rgba(50, 39, 64, 0.1);
}
.dataTablesCard {
  background-color: #fff;
  border-radius: 8px;
}
.dataTablesCard.border-no td {
  border-top: 0 !important;
}

.margin-top {
  margin-top: 130px;
}
/* NAVBAR */
.mobile,
.burger {
  display: none;
}

a.navbar-brand {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #2042b8 !important;
}

a.nav-link {
  /* font-family: "Jost", sans-serif; */
  /* font-style: normal;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.75px;
  color: #000 !important;
  font-family: "Rubik", sans-serif; */
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16.3276px;
  color: #080a0c !important;
}

a.nav-link:hover {
  color: #4c9aff !important;
}

a.nav-link.active {
  color: #4c9aff !important;
}

.bg-color {
  background: transparent;
}
a.nav-link.active {
  color: #4c9aff !important;
}

/* Mobile Navbar */
@media screen and (max-width: 920px) {
  .navbar {
    display: none !important;
  }

  .mobile,
  .burger {
    display: block !important;
    padding: 3px 7px;
    background: transparent;
  }

  .burger {
    display: block;
    position: absolute;
    width: 34px;
    padding: 3px;
    cursor: pointer;
    z-index: 3;
    right: 1.5rem;
    border-radius: 20px;
    top: 1.5rem;
    height: 34px;
    padding: 9px;
  }

  .burger span {
    width: 100%;
    height: 2px;
    background-color: #0747a6;
    display: block;
    margin-bottom: 4px;
    transition: all 0.4s;
  }
  .burger span.switch {
    background-color: white;
  }
  .burger.open span:nth-child(1) {
    transform: translateY(4px) rotate(40deg);
  }
  .burger.open span:nth-child(2) {
    transform: translateY(-2px) rotate(-40deg);
    height: 0;
  }
  .burger.open span:nth-child(3) {
    transform: translateY(-6px) rotate(-40deg);
  }
  .burger.open span {
    margin-top: 4px;
  }
  span.dif {
    width: 70%;
    margin-left: 6px;
  }
  a.mnav {
    font-family: "Jost", sans-serif;
    font-style: 700;
    font-weight: bold;
    font-size: 18px;
    color: #fff !important;
    position: absolute;
    top: 10px;
    left: 25px;
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
  nav .links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    background-color: #fff;
    height: max-content;
    transform: translateY(-100%);
    transition: all 0.7s;
    z-index: 2;
    padding: 1.5rem;
    height: 100vh;
    /* border-radius: 0 0 520px; */
  }
  nav .links.open {
    transform: translateY(0%);
  }
  nav .links p {
    color: #4c9aff;
    font-family: "Jost", sans-serif;
  }
  nav ul {
    flex-direction: column;
    height: auto;
    justify-content: space-between;
  }
  ul li {
    margin: 30px 0px;
  }
  ul a {
    /* color: #fff; */
    font-size: 20px;
    font-family: "Jost", sans-serif;
    text-decoration: none;
  }
  .links ul a {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #0747a6;
  }
  .links ul a.active {
    color: #4c9aff !important;
  }
  ul a::after {
    background-color: pink;
    opacity: 0;
    height: 10px;
  }
  ul a:hover::after {
    width: 100%;
    opacity: 1;
  }
}
/* END NAVBAR */

.heading {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 38.442px;
  text-align: center;
  color: #0747a6;
  width: 400px;
}
.get-started {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18.5129px;
  color: #525252;
  padding: 15px;
}
.order-card {
  background: #ffffff;
  border: 0.872902px solid #e6e6e6;
  box-shadow: 0px 40.1535px 27.9329px rgba(0, 0, 0, 0.1);
  border-radius: 8.72902px;
  width: 410px;
}
.order-card-lg {
  background: #ffffff;
  border: 0.872902px solid #e6e6e6;
  box-shadow: 0px 40.1535px 27.9329px rgba(0, 0, 0, 0.1);
  border-radius: 8.72902px;
  width: 900px;
}
/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 17.458px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #525252;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 0.912579px solid #0747a6;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2.3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0747a6;
}
.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #7c7c7c;
  font-family: "DM Sans";
}

.form-control {
  background: #f6f8ff !important;
  border: 0.752731px solid #c6cfee !important;
  border-radius: 4.36451px !important;
}

.container_ {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_ input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container_ input:checked ~ .checkmark {
  background-color: #0747a6;
  border: 1px solid #0747a6;
}
.container_ input:checked ~ .checkmark:after {
  display: block;
}
.container_ .checkmark:after {
  left: 35%;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .container_ .checkmark {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.container_ .checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

.menu-flags {
  background-color: #f6f8ff !important;
  box-sizing: border-box;
  border: 0.752731px solid #c6cfee;
  border-radius: 4.36451px;
}

input,
select,
.h-46 {
  height: 46px !important;
}
.dropdown-menu {
  background: #ffffff !important;
  box-shadow: 0px 15px 21px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 1rem 0rem !important;
  border: none !important;
  max-height: 210px;
  overflow: scroll;
}

.input-width {
  width: 210px !important;
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-width-2 {
  width: 225px !important;
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-width-crypto {
  width: 120px !important;
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.default-select {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-width::placeholder,
.input-width-2::placeholder,
textarea::placeholder,
.input-width-crypto::placeholder {
  font-size: 12px !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.rate span {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13.0935px;
  line-height: 17px;
  color: #7c7c7c;
}

.rate p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #525252;
}

.btn-primary {
  color: #fff;
  background-color: #0747a6 !important;
  border: 1.07733px solid #c6cfee !important;
  border-radius: 6.98321px !important;
}

.footer span,
.footer a {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b8b8b8;
}

.live-trans-container .circlemark {
  width: 16px;
  height: 16px;
  background: #008751;
  border-radius: 50%;
}

.live-trans-container {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16.1599px;
  line-height: 21px;
  color: #7c7c7c;
}

.parent-box {
  position: relative;
  top: 0;
  left: 0;
}
.parent-image1 {
  position: relative;
  top: 0;
  left: 17px;
  z-index: 100;
}
.parent-image2 {
  position: absolute;
}

.marquee h6 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 123.5%;
  color: #828282;
}

.trans-time {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #b8b8b8;
}

.marquee p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #000000;
}

.login h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  color: #414141;
}

.login p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #8b8b8b;
}
.login .terms {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
}

.font-size-12 {
  font-size: 12px;
}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-style {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #ffffff;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.login .close {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 15.0826px;
  color: #4c9aff;
}
.user-email {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #575757;
}
.inputStyle input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.6rem;
  font-size: 2rem;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
}

.receiving-details .title,
.receiving-details.title,
.confirm-purchase .title {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #525252;
}

.receiving-details p {
  width: 300px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #525252;
}

.cursor-pointer {
  cursor: pointer !important;
}

.NB {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b8b8b8;
  width: 300px;
}

.payment p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #525252;
}
.payment h6 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 32.4396px;
  color: #0747a6;
}

.payment span {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4c9aff;
}

.recipientAdd {
  display: flex;
  border: 1px solid #4c9aff;
  border-radius: 6px;
}

.recipientAdd h6 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
}
.recipientAdd p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #525252;
}

.margin-top-sm {
  margin-top: 130px;
}

.buycrypto h6 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #525252;
}
.buycrypto p {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #0747a6;
}

.buycrypto p span {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4c9aff;
}

.buycrypto p.dark {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #242424;
}

.buycrypto p.dark span {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #7c7c7c;
}

.buycrypto .rate {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #b8b8b8;
}

.bank-details {
  border: 1px solid #e6e6e6;
  border-radius: 11px;
}
.bank-details .title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #525252;
}
.bank-details .head {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #525252;
}
.bank-details .subhead {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #080a0c;
}

.timing p {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #525252;
}

.timing .title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #7c7c7c;
}

.appeal .text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}
.appeal .chat {
  background: #f9f9f9;
  border-radius: 3.81159px;
  height: 406px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.appeal .msg_cont {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3804px;
  color: #525252;
  background: #eef3ff;
  border-radius: 0 12px 12px 12px;
}
.appeal .msg_cont_sender {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3804px;
  color: #525252;
  background: #d3dbee;
  border-radius: 0 12px 12px 12px;
}
.appeal .sender {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3804px;
  color: #000000;
}
.appeal .sender_time {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3804px;
  color: #7c7c7c;
}
.chatbtm.footer {
  position: sticky;
  height: 60px;
  background: #f9f9f9;
  bottom: 0;
}
.d-appeal {
  display: none;
}
.margin-top-xs {
  margin-top: 130px;
}

.trans-details h6 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #525252;
}
.trans-details p {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #080a0c;
}

.trans-head {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #525252;
}

.form-control-2 {
  background: #ffffff;
  border: 0.752731px solid #c6cfee;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.07);
  border-radius: 4.36451px;
  width: 100%;
}

select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==) !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  background-position-y: 50% !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
}
select::-ms-expand {
  display: none;
}
.select-lg {
  height: 56px !important;
}

@media screen and (max-width: 600px) {
  .order-card {
    width: 350px;
  }
  html {
    overflow-x: hidden;
  }
  body {
    background-image: url("./images/bottombg.svg"), url("./images/rightbg.svg");
    background-position: center bottom, -50% top;
    background-repeat: no-repeat, no-repeat;
    min-height: 100vh;
  }

  .inputStyle input {
    width: 2.4rem !important;
    height: 2.4rem !important;
    margin: 0 0.3rem;
  }
  .margin-top {
    margin-top: 0px;
  }
  .margin-top-sm {
    margin-top: 60px;
  }
  .heading {
    width: 300px;
    margin: auto;
  }
  .get-started {
    font-size: 18.5129px;
    padding: 20px;
  }
  .input-width {
    width: 170px !important;
  }
  .input-width-2 {
    width: 170px !important;
  }

  .input-width-crypto {
    width: 78px !important;
  }

  .d-appeal {
    display: block;
  }
  .order-card-lg {
    width: fit-content;
  }
  .margin-top-xs {
    margin-top: 10px;
  }
  .hide-appeal {
    display: none;
  }
}
